/* eslint-disable react/no-unescaped-entities */
import "aos/dist/aos.css";
import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { RiMailSendLine } from "react-icons/ri";
import homeLogo from "../../Assets/home-main.svg";
import uparrow from "../../Assets/Media/up-arrow.png";
import About from "../About/About";
import Certificates from "../Certificates/Certificates";
import Contact from "../Contact/Contact";
import Experiences from "../Experiences/Experiences";
import Particle from "../Particle";
import Projects from "../Projects/Projects";
import Resume from "../Resume/ResumeNew";
import Home2 from "./Home2";
import Type from "./Type";
function Home() {
  const [scroll, setScroll] = useState(0);
  // useEffect(() => {

  //   window.onscroll = function(ev) {

  //     if(window.scrollY>1000){
  //       // const mbtn = document.getElementsByClassName("btmbtn2")
  //       // mbtn[0].style.display = "block !important"

  //       // const ubtn = document.getElementsByClassName("btmbtn1")
  //       // ubtn[0].style.display = "block !important"
  //       // console.log( ubtn[0].style)

  //       //document.getElementsByClassName("btmbtn1")[0].style.display = "block !important"

  //     }
  //     // if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
  //     //    setTimeout(function() {
  //     //       window.location.href = "/about";
  //     //    }, 1000)
  //     // }
  //   };

  // });

  function scrollHandler() {
    setScroll(window.scrollY);
  }
  window.addEventListener("scroll", scrollHandler);
  const gotoTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div>
      <section>
        <Container fluid className="home-section" id="home">
          <Particle />
          <Container className="home-content">
            <Row>
              <Col md={7} className="home-header">
                <h1 style={{ paddingBottom: 15 }} className="heading">
                  Hi There!{" "}
                  <span className="wave" role="img" aria-labelledby="wave">
                    👋🏻
                  </span>
                </h1>

                <h1 className="heading-name">
                  I'M
                  <strong className="main-name"> Sumon Biswas</strong>
                </h1>

                <div style={{ padding: 50, textAlign: "left" }}>
                  <Type />
                </div>
              </Col>

              <Col md={5} style={{ paddingBottom: 20 }}>
                <img src={homeLogo} alt="home pic" className="img-fluid" />
              </Col>
            </Row>
          </Container>
        </Container>
        <Home2 />

        <About />
        <Experiences />
        <Projects />
        <Certificates />
        <Resume />
        <Contact />
      </section>
      {scroll > 600 && (
        <a id="upbtn">
          <img
            className="btmbtn btmbtn2"
            alt="Up Arrow"
            src={uparrow}
            onClick={gotoTop}
          ></img>
        </a>
      )}
      <a href="mailto:contact@sumon.com.bd">
        <RiMailSendLine className="btmbtn btmbtn1"></RiMailSendLine>
      </a>
    </div>
  );
}

export default Home;
