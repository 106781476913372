import React, { useCallback } from "react";
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
function Particle() {
  const particlesInit = useCallback(async (engine) => {
    // you can initialize the tsParticles instance (engine) here, adding custom shapes or presets
    // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
    // starting from v2 you can add only the features you need reducing the bundle size
    await loadFull(engine);
  }, []);

  const particlesLoaded = useCallback(async (container) => {
    // await console.log(container);
  }, []);
  return (
    <>
      <Particles
        init={particlesInit}
        loaded={particlesLoaded}
        options={{
          particles: {
            number: {
              value: 160,
              density: {
                enable: true,
                value_area: 1500,
              },
            },
            line_linked: {
              enable: false,
              opacity: 0.03,
            },

            move: {
              directions: "right",
              speed: 0.2,

              enable: true,
              outModes: {
                default: "bounce",
              },
              random: false,

              straight: false,
            },
            size: {
              value: 1,
            },
            opacity: {
              anim: {
                enable: true,
                speed: 1,
                opacity_min: 0.05,
              },
            },
          },
          // interactivity: {
          //   events: {
          //     onclick: {
          //       enable: true,
          //       mode: "push",
          //     },
          //   },
          //   modes: {
          //     push: {
          //       particles_nb: 1,
          //     },
          //   },
          // },
          interactivity: {
            events: {
              onClick: {
                enable: true,
                mode: "push",
              },
              onHover: {
                enable: true,
                mode: "repulse",
              },
              resize: true,
            },
            modes: {
              push: {
                quantity: 4,
              },
              repulse: {
                distance: 20,
                duration: 0.01,
              },
            },
          },
          retina_detect: true,
        }}
      />
      {/* <Particles
            id="tsparticles"
            init={particlesInit}
            loaded={particlesLoaded}
            options={{
                
                fpsLimit: 120,
                interactivity: {
                    events: {
                        onClick: {
                            enable: true,
                            mode: "push",
                        },
                        onHover: {
                            enable: true,
                            mode: "repulse",
                        },
                        resize: true,
                    },
                    modes: {
                        push: {
                            quantity: 4,
                        },
                        repulse: {
                            distance: 200,
                            duration: 0.4,
                        },
                    },
                },
                particles: {
                  
                  line_linked: {
                    enable: false,
                    opacity: 0.03,
                  },
                  
          
                 
                    color: {
                        value: "#ffffff",
                    },
                    links: {
                        color: "#ffffff",
                        distance: 150,
                        enable: true,
                        opacity: 0.5,
                        width: 1,
                    },
                    collisions: {
                        enable: true,
                    },
                    move: {
                      directions: "right",
                    speed: 0.1,
                        
                        enable: true,
                        outModes: {
                            default: "bounce",
                        },
                        random: false,
                    
                        straight: false,
                    },
                    number: {
                      
                        density: {
                            enable: true,
                            area: 1500,
                        },
                        value: 160,
                    },
                    opacity: {
                      anim: {
                        enable: true,
                        speed: 1,
                        opacity_min: 0.05,
                      },
                        value: 0.5,
                    },
                    shape: {
                        type: "circle",
                    },
                    size: {
                        value: { min: 1, max: 5 },
                    },
                },
                detectRetina: true,
            }}
        /> */}
    </>
  );
}

export default Particle;
