import React, { useEffect } from "react";
import { Container } from "react-bootstrap";

import Particle from "../Particle";

import Aos from "aos";

function Educations() {
  useEffect(() => {
    Aos.init({ duration: 1000 });
    Aos.init({ once: true });
  }, []);
  const education = [
    {
      title: "Lead Software Engineer",
      org: "DigitalKanect, Dhaka",
      works: [
        "Led the team for the design and development of multiple enterprise-level applications and developed the system of the products.",
        "Managed all the backend operations and worked with other frontend & app developers, and designers, to make the best end products.",
        "Developed applications with escrow services, calling apps, different international payment methods, instant communications, etc.",
        "Handled all the cloud operations using the latest technologies of AWS, CI/CD, Firebase, NodeJS, NextJS, PostgreSQL, and many more",
      ],
      fromDate: "February, 2023",
      toDate: "Now",
    },
    {
      title: "Software Engineer",
      org: "Softcent, Estonia",
      works: [
        "I mainly handle all backend activities as well as the frontend. My focus is to deliver a complete product with my team.",
        "In Softcent, we have built fintech apps as well as others. I have worked on Stripe, Revolut and Paypal payment systems. We also developed apps on microservices.",
        "Also worked as a scrum master. Contribued on project managements",
      ],
      fromDate: "April, 2022",
      toDate: "January, 2023",
    },
    {
      title: "Full Stack Developer (Contractual)",
      org: "Dayitb, Dhaka, Bangladesh",
      works: [
        "My job was to convert a PHP web application into MERN stack. Also to develop an admin panel to control the whole operations.",
        "Collaborate with other developers and management.",
      ],
      fromDate: "January, 2022",
      toDate: "March, 2022",
    },
    {
      title: "Full Stack Developer (Freelance)",
      org: "Fiverr / Upwork",
      works: [],
      fromDate: "2020",
      toDate: "2022",
    },
  ];

  return (
    <Container fluid className="experience-section">
      <Particle />

      <Container>
        <section
          className="resume-section p-3 p-lg-5 d-flex align-items-center"
          id="education"
        >
          <div className="w-100">
            <h2 className="mb-5">Work Experiences</h2>
            {education.map((data, index) => (
              <div
                key={index}
                className="resume-item d-flex flex-column justify-content-between mb-5"
              >
                <span className="text-primary experience-date">
                  {data.fromDate} - {data.toDate}
                </span>
                <div className="resume-content">
                  <h3 className="subheading mb-0">
                    {">>"} {data.title}
                  </h3>
                  <div className="subheading mb-3">{data.org}</div>
                  {data.works.map((d, i) => (
                    <div
                      key={i}
                      data-aos={i % 2 === 0 ? "zoom-in-left" : "zoom-in-right"}
                      className="subheading mb-3 work-details"
                    >
                      {"* " + d}
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </section>
      </Container>
    </Container>
  );
}

export default Educations;
