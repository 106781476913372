import React from "react";
import Typewriter from "typewriter-effect";

function Type() {
  return (
    <Typewriter
      options={{
        strings: [
          "Lead Software Engineer",
          "Full-Stack Developer",
          "Android Application Developer",
          "MERN Stack Developer",
          "Open Source Contributor",
        ],
        autoStart: true,
        loop: true,
        delay: 30,
        pauseFor: 500,
        deleteSpeed: 15,
      }}
    />
  );
}

export default Type;
