/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import {
  DiBootstrap,
  DiCss3,
  DiHtml5,
  DiJava,
  DiJavascript1,
  DiMongodb,
  DiMysql,
  DiNodejs,
  DiPhp,
  DiProlog,
  DiPython,
  DiReact,
} from "react-icons/di";

import {
  SiApachekafka,
  SiGnubash,
  SiPostgresql,
  SiRevolut,
  SiTypescript,
} from "react-icons/si";
// json, c, xml, bash, express
import Aos from "aos";
import "aos/dist/aos.css";
import { FaStripe } from "react-icons/fa";
import { TbBrandNextjs } from "react-icons/tb";
function Techstack() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
    const script = document.createElement("script");
    script.src = "https://kit.fontawesome.com/b00ddaab40.js";
    script.crossorigin = "anonymous";
    script.async = true;
    document.body.appendChild(script);
  }, []);
  //     XML | JSX
  // ExpressJS |  Microservices
  //   GCP | Fintech
  //   Appium
  return (
    <Row
      data-aos="fade-left"
      style={{ justifyContent: "center", paddingBottom: "50px" }}
    >
      <Col xs={4} md={2} className="tech-icons">
        <DiJavascript1 />
        <p className="techName">JavaScript</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <i className="fa-brands fa-golang"></i>
        <p className="techName">Go</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <i className="fa-solid fa-c"></i>
        <p className="techName">C</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiJava />
        <p className="techName">Java</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiNodejs />
        <p className="techName">NodeJS</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiReact />
        <p className="techName">ReactJS</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <TbBrandNextjs />
        <p className="techName">NextJS</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiTypescript />
        <p className="techName">TypeScript</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiPython />
        <p className="techName">Python</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiProlog />
        <p className="techName">Prolog</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiGnubash />
        <p className="techName">Bash</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiBootstrap />
        <p className="techName">Bootstrap</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiHtml5 />
        <p className="techName">HTML5</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiPostgresql />
        <p className="techName">PostgreSQL</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiMysql />
        <p className="techName">MySQL</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiMongodb />
        <p className="techName">MongoDB</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiCss3 />
        <p className="techName">CSS3</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiPhp />
        <p className="techName">PHP</p>
      </Col>

      <Col xs={4} md={2} className="tech-icons">
        <SiApachekafka />
        <p className="techName">Apache Kafka</p>
      </Col>

      <Col xs={4} md={2} className="tech-icons">
        <FaStripe />
        <p className="techName">Stripe</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiRevolut />
        <p className="techName">Revolut</p>
      </Col>

      <Col xs={4} md={2} className="tech-icons">
        <i className="fa-brands fa-sass"></i>
        <p className="techName">SaaS</p>
      </Col>
    </Row>
  );
}

export default Techstack;
