/* eslint-disable react/no-unescaped-entities */
import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Particle from "../Particle";
import ProjectCard from "./ProjectCards";

import Aos from "aos";
import "aos/dist/aos.css";
import bdbuddy from "../../Assets/Projects/bdbuddy.jpg";
import bincal from "../../Assets/Projects/bincal.jpg";
import coinspaid from "../../Assets/Projects/coinspaid.png";
import kanect from "../../Assets/Projects/digitalkanect.png";
import fcom from "../../Assets/Projects/fcom.png";
import feelio from "../../Assets/Projects/feelio.png";
import revorium from "../../Assets/Projects/revorium.png";
import shop from "../../Assets/Projects/shop.jpg";
import tour from "../../Assets/Projects/tour.jpg";
import url from "../../Assets/Projects/url.jpg";
import vovo from "../../Assets/Projects/vovo.png";

function Projects() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col data-aos="fade-down" md={4} className="project-card">
            <ProjectCard
              imgPath={kanect}
              isBlog={false}
              title="DigitalKanect: Connecting you to the best"
              description="This platform will connect all the talents with the mass people of Bangladesh. Users can hire
              freelancers/workers, get tutors for their needs, can get a new house, can reach influencers to
              make their brand deals for marketing. We will also give invoices and escrow services to
              business people and freelance workers."
              link="https://digitalkanect.com/"
            />
          </Col>
          <Col data-aos="fade-down" md={4} className="project-card">
            <ProjectCard
              imgPath={feelio}
              isBlog={false}
              title="Feelio: Connecting you to the world"
              description="It is a dating platform where users can call and text and also can send tips. 
              I have developed the server and designed the app."
              link="https://www.feelio.online/"
            />
          </Col>
          <Col data-aos="fade-down" md={4} className="project-card">
            <ProjectCard
              imgPath={fcom}
              isBlog={false}
              title="FCom: Automation for F-Commerce"
              description="I have developed the whole application from scratch where f-commerce sellers can sell their products very conveniently.
              Users can order through the web app as well as calling the hotline. Our aim is to create a super easy online shop."
              link="https://fcom-seller.vercel.app/"
            />
          </Col>
          <Col data-aos="fade-down" md={4} className="project-card">
            <ProjectCard
              imgPath={revorium}
              isBlog={false}
              title="Revorium: Get paid from anywhere"
              description="It is a fintech application that helps Bangladeshi freelancers to receive their money from
              foreign clients and sell their digital products conveniently. I have developed all the
              backend as well as all the functionalities on the frontend (also complete admin panel).
              I have worked on NodeJS, ReactJS, PostgreSQL, Sequelize, Paypal, Stripe, Revolut,
              Puppeteer, Multer, AWS, Firebase Cloud Messaging etc. for building this."
              link="https://revorium.com/"
            />
          </Col>
          <Col data-aos="fade-down" md={4} className="project-card">
            <ProjectCard
              imgPath={coinspaid}
              isBlog={false}
              title="Coinspaid"
              description="Coinspaid is an international client who works on cryptocurrency. I have developed a
              single landing page for them using NextJS, NodeJS, and MySQL. There is an interesting
              world map that shows the interconnectivity between all the recent baker nodes."
              link="https://bdbuddy.xyz/"
            />
          </Col>
          <Col data-aos="fade-down" md={4} className="project-card">
            <ProjectCard
              imgPath={vovo}
              isBlog={false}
              title="Contently: Make Contents with AI"
              description="Contently is a content making platform. Here users can convert texts into audio and add
              with any video or images they want. Multiple videos can also be merged here. Users can
              add subtitle if they want."
              link="https://vovo-918de.web.app/"
            />
          </Col>

          <Col data-aos="fade-down" md={4} className="project-card">
            <ProjectCard
              imgPath={bdbuddy}
              isBlog={false}
              title="BDBUDDY: EMERGENCY SITUATION HANDLING SYSTEM"
              description="This system will help the users in their dangerous situations. They can ask for police
              stations, fire services, ambulances etc. This application will also help the stations."
              link="https://bdbuddy.xyz/"
            />
          </Col>

          <Col data-aos="fade-down" md={4} className="project-card">
            <ProjectCard
              imgPath={url}
              isBlog={false}
              title="FREE URL SHORTENER"
              description="You can shrink any kind of big URL to a short one. It works like goo.gl or bit.ly
              But the difference is, it is minimal and free. Most importantly you can check how many
              times your short URLs are clicked worldwide.
              "
              link="https://link.sumon.com.bd/"
            />
          </Col>

          <Col data-aos="fade-down" md={4} className="project-card">
            <ProjectCard
              imgPath={tour}
              isBlog={false}
              title="TOUR MATE FINDER - TOURBUDDY"
              description="This web application can help you find your tourmate for a specific date and a specific
              place"
              link="https://tourbuddy.sumon.com.bd/"
            />
          </Col>

          <Col data-aos="fade-up" md={4} className="project-card">
            <ProjectCard
              imgPath={shop}
              isBlog={false}
              title="SHOP INVENTORY MANAGEMENT SYSTEM"
              description="This is a simple 'Shop Inventory Management System'. The main focusing language here
              are jQuery and web SQL"
              link="https://shop.sumon.com.bd/"
            />
          </Col>

          <Col data-aos="fade-up" md={4} className="project-card">
            <ProjectCard
              imgPath={bincal}
              isBlog={false}
              title="BINARY ARITHMETIC CALCULATOR"
              description="This is a web application made using HTML, CSS, and Vanilla Javascript. You can do any
              arithmetic operation of binary digits here. The decimal values are also shown at the
              bottom!"
              link="https://bincal.sumon.com.bd/"
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
